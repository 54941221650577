import type { Breakpoints, Colors, Fonts, Container } from 'types/theme'

import media from 'styles/media'

export const breakpoints: Breakpoints = {
  xxs: 0,
  xs: 360,
  s: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1921,
}

export const colors: Colors = {
  primary: '#CFDED7',
  secondary: '#1C5868',
  tertiary: '#B1C2BA',
  light: '#FFEBC3',
  dark: '#1D4648',
  dark100: '#002238',
  dark200: '#1C5868',
  gray: '#BFC9C6',
  gray100: '#E1E8E6',
  black: '#000000',
  white: '#FFFFFF',
  white100: '#F1F1F1',
  danger: '#CA0F0F',
}

export const fonts: Fonts = {
  size: '16px',
}

export const container: Container = {
  widthNav: '1920px',

  widthXL: '1760px',
  width: '1200px',

  widthSlimXL: '1520px',
  widthSlim: '1100px',

  widthNarrowXL: '1300px',
  widthNarrow: '750px',

  paddingMD: '2rem',
  padding: '2rem',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  container,
  media: media(),
}

export default theme
